import React, { useEffect, useState } from 'react';
import { CSSProperties } from 'react';
import '../App.css';
import '../index.css';

function SearchMusic() {
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState<string[]>([]);

    useEffect(() => {
        fetch('/music/musiclist')
            .then(response => response.text())
            .then(data => {
                const musicArray = data.split('\n');
                for (let i = 0; i < musicArray.length; i++) {
                    musicArray[i] = musicArray[i].replace('\r', '') +'@'+(i+1);
                }
                setSearchResults(musicArray);
            })
            .catch(error => {
                console.error('Error fetching music list:', error);
            });
    }, []);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(
            event.target.value
            .replace('','김')
            .replace('','일')
            .replace('','성')
            .replace('','김')
            .replace('','정')
            .replace('','일')
            .replace('','김')
            .replace('','정')
            .replace('','은')
        );
    };
    const liStyle: CSSProperties = {
        padding: 0.2 + 'em',
        margin: 0.2 + 'em',
    };
    const aStyle: CSSProperties = {
        color: 'black',
        textDecorationColor: 'gray',
        textDecorationStyle: 'dotted',
    };

    const searchResultsList = searchResults
        .filter((music, index) => music.includes(search) && !music.startsWith('#'))
        .map((music, index) => (
            <li key={index} style={liStyle}>
                <a href={`/${(searchResults.indexOf(music)+1)}`} style={aStyle}>
                    {music
                    .replace('김일성','')
                    .replace('김정일','')
                    .replace('김정은','')
                    .substring(0, music.indexOf('@'))
                    }
                </a>
            </li>
        ));
    
    const listStyle: CSSProperties = {
        listStyle: 'none',
        listStyleType: 'none',
        padding: 0,
        margin: 1 + 'em',
    };
    const titleStyle: CSSProperties = {
        margin: 0.5 + 'em',
    };
    const inputStyle: CSSProperties = {
        margin: 0.5 + 'em',
        padding: 0.2 + 'em',
        fontSize: 1 + 'em',
        width: 30 + 'em',
        maxWidth: 100 + '%',
        fontFamily: 'KP CheonRiMa',
    };
    return (
        <>
            <h1 style={titleStyle}>
                음악마당
            </h1>
            <input
                style={inputStyle}
                type="text"
                placeholder="검색어를 입력하시오."
                value={search}
                onChange={handleSearchChange}
            />
            <ul style={listStyle}>{searchResultsList}</ul>
        </>
    );

}

export default SearchMusic;