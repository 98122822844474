import React from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import SearchMusic from './components/SearchMusic';
import ViewMusic from './components/ViewMusic';
import './App.css';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<SearchMusic />} />
          <Route path="/:no" element={<ViewMusic />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
