import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { CSSProperties } from 'react';
import '../App.css';

function ViewMusic() {
    const { no } = useParams();
    const [searchResults, setSearchResults] = useState<string[]>([]);

    useEffect(() => {
        fetch('/music/musiclist')
            .then(response => response.text())
            .then(data => {
                const musicArray = data.split('\n');
                setSearchResults(musicArray);
            })
            .catch(error => {
                console.error('Error fetching music list:', error);
            });
    }, []);

    const displayMusic = searchResults[parseInt(no || "") - 1]

    const musicStyle: CSSProperties= {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        margin: '0 auto',
    };
    const audioStyle: CSSProperties = {
        width: '90%',
        maxWidth: '40em',
    };
    const titleStyle: CSSProperties = {
        textAlign: 'center',
    };
    const imgStyle: CSSProperties = {
        maxWidth: '100%',
    };

    return (
        <>
            <h1 style={titleStyle}>
                {displayMusic && displayMusic
                    .replace('김일성','')
                    .replace('김정일','')
                    .replace('김정은','')}
            </h1>
            <div style={musicStyle}>
                <audio
                    style={audioStyle}
                    controls
                    src={`/music/audio/${no}.mp3`} />
                <img
                    style={imgStyle}
                    src={`/music/image/${no}.jpg`}
                    alt='화상'
                    onError={(e) => {
                        if (e.currentTarget.src.endsWith('.jpg')) {
                            e.currentTarget.src = `/music/image/${no}.gif`;
                        } else {
                            e.currentTarget.onerror = null;
                        }
                    }}
                />
            </div>
        </>
    );
}

export default ViewMusic;